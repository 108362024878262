
  
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: white;
  }
  .container__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(9.5px);
    background-color: #E08A00;
    border-radius: 8px;
    overflow: hidden;
    padding: 1rem;
    height: 500px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      height: 700px;
      }
  }
  
  .kontakt_head_text{
    display:flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    h2{
      font-size: 2rem;
    }
    p{
      font-size: 1rem;
      text-transform: uppercase;
    }
  }
  .right_text{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p{
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      @media screen and (max-width: 800px) {
        font-size: 1.25rem;
     }
      @media screen and (max-width: 400px) {
        font-size: 1rem;
     }
    }
    a{
            all: unset;
            color: #402800;
            cursor: pointer;

    }
}
  .divider{
    width: 2px;
    background-color: white;
    height: 80%;
    @media screen and (max-width: 800px) {
      width: 80%;
      height: 2px;
      margin:1rem;
      }
  }
  .card {
    width: 55%;
    @media screen and (max-width: 800px) {
      
      width: 100%;
      }
  }
  
  h1 {
    margin: 2rem 2rem 0;
    font-size: 2rem;
  }
  
  .form {
    padding: 2rem;
    @media screen and (max-width: 500px) {
      padding: 0.5rem;
    }
    p{
      height: 1.5rem;
      padding-left: 0.3rem;
    }
    button {
      display: block;
      width: 100%;
      align-self: right;
      background-color: #402800;
      border: none;
      padding: 0.5rem 2rem;
      font-size: 1rem;
      color: #fff;
      font-family: inherit;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border-radius: 50px;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
              &:hover{
              transform: scale(1.02)
              }
    }
  }
  
  .dialog_text{
    font-family: var(--font-base) !important;
  }

  .kontakt_session{
    margin: 4rem 0;
    @media  screen and (max-width: 500px){
        padding:  0 1rem;
    }
  }
  
  @mixin input {
    display: block;
    width: 100%;
    border: 2px solid #eee;
    padding: 1ch;
    border-radius: 10px;
    transition: 150ms ease;
    outline: none;
    font-size: 1.25rem;
    font-family: inherit;
  }
  
  @mixin input-focus {
    border-color: #402800;
  }
  
  @mixin label {
    position: absolute;
    top: 1ch;
    left: 1ch;
    padding: 0 .5ch;
    pointer-events: none;
    transition: 150ms ease;
    color: #888;
  }
  
  @mixin label-focus {
    position: absolute;
    top: 0;
    font-size: 0.9em;
    color: var(--secondary-color);
    font-weight: bold;
    transform: translateY(-50%);
    background-color: white;
    font-weight: 600;
    border-radius: 10px;
  }
  
  
  .text-input {
    position: relative;
    margin-bottom: 1rem;
    label {
      @include label;
    }
    label.label-focus {
      @include label-focus;
    }
    input {
      @include input;
    }
    input.input-focus {
      @include input-focus;
    }
  }
  
  .text-area {
    position: relative;
    margin-bottom: 1rem;
    label {
      @include label;
    }
    label.label-focus {
      @include label-focus;
    }
    textarea {
      @include input;
      height: 8em;
    }
    textarea.input-focus {
      @include input-focus;
    }
  }