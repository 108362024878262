.angebot_head {
  padding: 3rem 0rem 1rem 1rem;

  h2 {
    font-size: 2rem;
    color: #343434;
  }
  p {
    font-size: 1rem;
    text-transform: uppercase;
  }
}

.info-line {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  width: fit-content;
}

.info-wrapper {
  padding: 1rem 1rem 1rem 0;
}

.info-question {
  font-style: bold;
}
.offer-mini-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 0.2rem;
}
.grid_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 30px;
  max-width: 1400px;
  margin: 0 auto;
  @media screen and (max-width: 1060px) {
    grid-template-columns: 1fr;
  }

  .grid_item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    width: 100%;
    @media screen and (max-width: 1060px) {
      margin-top: 1rem !important;
      margin-bottom: 0 !important;
    }
    @media screen and (max-width: 480px) {
      width: fit-content;
      margin: auto;
    }
    .offer_img_wrapper {
      display: inline-block;
      width: 100%;
      img {
        width: 850px;
        height: 600px;
        object-fit: fill;
        border-radius: 8px;
        transition: 0.2s;
        scale: 1.025;
        @media screen and (max-width: 900px) {
          width: 700px;
          height: 550px;
        }
        @media screen and (max-width: 750px) {
          width: 600px;
          height: 475px;
        }
        @media screen and (max-width: 600px) {
          width: 450px;
          height: 400px;
        }
        @media screen and (max-width: 480px) {
          max-width: 400px;
          height: 325px;
        }
        @media screen and (max-width: 400px) {
          max-width: 350px;
          height: 275px;
        }
        @media screen and (max-width: 366px) {
          max-width: 320px;
          height: 275px;
        }
        @media screen and (max-width: 325px) {
          max-width: 280px;
          height: 250px;
        }
      }
    }

    .offer_text_wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 0 4rem 2rem;
      color: white;
      font-size: 1.5rem;
      width: 100%;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      transition: height 0.5s ease-in-out;
      background-color: rgba(51, 51, 51, 0.438);
      @media screen and (max-width: 750px) {
        font-size: 1rem;
      }
    }
  }
}
.text-btn {
  margin-top: 1rem;
  display: block;
  width: fit-content;
  align-self: right;
  background-color: var(--white-color);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
  color: var(--black-color);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  @media screen and (max-width: 750px) {
    font-size: 0.8rem;
    padding: 0.225rem 0.7rem;
  }
  @media screen and (max-width: 325px) {
    font-size: 0.8rem;
    padding: 0.1rem 0.5rem;
    margin-top: 0.5rem;
  }
  &:hover {
    color: var(--white-color) !important;
    background-color: var(--black-color);
    transform: scale(1.02);
  }
}

.navigation {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  img {
    width: auto;
    height: 80dvh;
    max-width: 90dvw;
    border-radius: 16px;
    @media screen and (max-width: 1170px) {
      height: 60dvh;
    }
    @media screen and (max-width: 900px) {
      width: 90dvw;
      height: auto;
    }
  }
}
.gallery-navdots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 100vw;
  .gallery-navdot-items {
    width: auto;
    height: 10dvh;
    border-radius: 16px;
    margin: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border: 3px solid;
    &:hover {
      background-color: var(--secondary-color);
    }
  }
}

.gallery_items-wrapper {
  overflow: hidden;
}

.gallery-wrapper {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  backdrop-filter: blur(5px);
  background-color: #000000c5;
}

.gallery-header {
  margin: 1rem;
  h2 {
    font-size: 1.7rem;
  }
}

.gallery-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1170px) {
    justify-content: center;
  }
}

.big-pics-gallery {
  display: inline-flex;
  width: 100%;
  transition: transform 1s ease-in-out;
  position: relative;
  div {
    display: flex;
    min-width: 100vw;
    justify-content: center;
  }
}
