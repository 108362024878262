.footer{
  background-color: #a0a0a0;
  color: #495057;
  padding: 50px 0;
  font-size: 16px;
display: flex;
justify-content: center;
align-items: center;
}



.footer-text {
  display: flex;
  justify-items: center;
  align-items: center;
  width: fit-content;
  gap: 8rem;
  flex-wrap: wrap;
  padding: 1rem;
  @media screen and (max-width: 800px) {
    gap: 3rem;
    width:100%;
}

  }
  .footer-links{
    display: flex;
    flex-direction: column;
  }
  
  .col-1,
.col-2,
.col-3 {
  height: 130px;
  /* Add the following properties */
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    margin: auto;
}
    p{
      display: flex;
      align-items: center;
    }
  }

.links{
  text-decoration: underline;
  color:black;
}