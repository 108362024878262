@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;
  --primary-color: #ffffff;
  --secondary-color: #FAC189;
  --blue-color: #89C2FA;
  --black-color: #353531;
  --lightGray-color: #e4e4e4;
  --gray-color: #5F5E5E;
  --green-color: #E1E096;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;

  
}