.app {
  background: var(--white-color);
    font-family: var(--font-base);
  }
  
  .app__whitebg {
    background-color: var(--white-color);
  }
  
  .app__primarybg {
    background-color: var(--primary-color);
  }
  
  .app__container {
    max-width: 100vw;
    display: flex;
    flex-direction: row;
  }

  .app__flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .app__wrapper {
    flex: 1;
    flex-direction: column;
    max-width: 100%;

  }
  
  .copyright {
    width: fit-content;
    padding: 2rem 0 0;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 4;
    @media screen and (max-width: 600px) {
      display: none;
    }
    p {
      text-align: end;
      text-transform: uppercase;
      color: var(--gray-color);
    }
  }
  
  .head-text {
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;
  
    span {
      color: var(--secondary-color);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }
  
  .p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--gray-color);
    line-height: 1.5;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
    }
  }
  
  .bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
  
  .app__social {
    position: fixed;
    bottom: 50%;
    left: 0;
    z-index: 1;
    &:hover{
      z-index: 101;
    }
    .app__social-wrapper{
      display: flex;
      transform: translateX(-295px);
      transition: transform 500ms ease-in;
      &:hover{
        transform: translateX(0);
        z-index: 101;
      }
      .app__social-button{
        width: 50px;
        height: 50px;
        border: 1px solid var(--lightGray-color);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        z-index: 2;
      svg {
        color: var(--white-color);
        transition: 0.3s ease-in-out ;
        font-size: 1.4rem;
      }
      &:hover {
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
        svg {
        transform: scale(1.15);
        background-color: #ff8000;
          color: var(--white-color);
        }
      }
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
        margin: 0.5rem 0;
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
    .app__social-card{
      width: 295px;
      height: 220px;
      background-color: #ff8000;
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      gap: 1rem;
      border-bottom-right-radius: 8px;
      .app__social-card-entry-divider{
        height: 1.0777px;
        width: 100%;
        background-color: #241200;
        margin-top: calc(1rem - 4px)
      }
      .app__social-card-entry{
        .app__social-card-entry-title{
          display: flex;
          align-items: center;
          color: white;
          padding-bottom: 0.3rem;
          h2{
            font-size: 1rem;
          }
          p{
            font-size: 0.8rem;
          }
          div{
            width: 1px;
            height: 10px;
            background-color: white;
            margin-left: 7px;
            margin-right: 7px;
          }
        }
        .app__social-card-entry-point{
          transform: translateY(-4px);
          div{
            display: flex;
            align-items: center;
            gap: 0.3rem;
            color: white;
            margin-bottom: 0.2rem;
            position: relative;
            padding: 0.2rem;
          }
          }
      }
    }
  }
  }
  
  .home{
    height: 100vh;
    @media screen and (max-width:1045px) {
    height: 1000px;  
    }
    @media screen and (max-width:800px) {
    height: 850px;  
    }
  }
  .project{
    height: fit-content;
  }


  .app__navigation-wrapper{
    position: fixed;
    right: 1rem;
    bottom: calc(50% - 2rem);
    z-index: 1;
  }
  .app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    width: fit-content;
    
    .app__navigation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #cbcbcb;
      margin: 0.5rem;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      
  
      &:hover {
        background-color: var(--secondary-color);
      }

     
  
      @media screen and (min-width: 2000px) {
        width: 20px;
        height: 20px;
      }
    }
  }
   
  @media screen and (max-width: 500px) {
    .app__navigation,
    .app__social {
      display: none;
    }
  
    .copyright {
      padding: 2rem;
    }
  }
  
  a.app__navigation-dot.active {
    background-color: var(--secondary-color);
}

.app__contact_popover{
background-color: var(--blue-color);
color: var(--white-color);
padding: 4rem;
}

// Datenschutzerklärung-Styling
.privacy-policy {
  max-width: 800px;
  margin: 0 auto; // Zentriert das Element
  padding: 20px;
  line-height: 1.6; // Verbessert die Lesbarkeit
  font-family: Arial, sans-serif;
  color: #333;

  h1 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #444;
    border-bottom: 1px solid #ddd; // Trennlinie unter den Überschriften
    padding-bottom: 5px;
  }

  p {
    font-size: 1em;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  a {
    color: #0073e6;
    text-decoration: underline;

    &:hover {
      color: #005bb5;
    }
  }

  // Optionales Button-Styling
  button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    background-color: #0073e6;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #005bb5;
    }
  }
}

