nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: transform 150ms ease-in-out;
}

nav.nav-bar--hidden {
  transform: translateY(-150%);
}
.app__navbar_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 2rem;
  width: 1250px;
  border-radius: 8px;
  margin-top: 0.75rem;
  transition: 0.2s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: rgb(255, 253, 251);

  @media screen and (max-width: 1300px) {
    width: 1000px;
  }
}
.app__navbar_logo_text {
  text-align: right;
  display: flex;
  align-items: flex-end;
  height: 100%;
  h2 {
    font-size: 0.8rem;
  }
}
.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  div {
    text-transform: uppercase;
    font-family: var(--font-base);
    font-size: 0.8rem;
  }
  img {
    width: 75px;

    @media screen and (min-width: 2000px) {
      width: 85px;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  list-style: none;

  li {
    margin: 0 1rem;
    cursor: pointer;
    flex-direction: column;
    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;
      margin: auto;
      margin-bottom: 5px;
    }

    a {
      text-decoration: none;
      flex-direction: column;
      font-size: 0.8rem;
      font-weight: 500;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color) !important;
      }
    }

    &:hover {
      div {
        background: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }
  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 1rem;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;
    z-index: 0;
    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        a {
          text-decoration: none;
          font-size: 1rem;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color) !important;
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}

a.app__navbar-link-item.active {
  color: var(--secondary-color) !important;
}
