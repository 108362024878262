.uberuns_container{
    width: 100%;
}
.uberuns_section{
    display: flex;
    flex-direction: row;
    padding: 2rem;
    width: 100%;
    gap: 1.5rem;
    @media screen and (max-width: 1375px) {
        flex-wrap: wrap;
        justify-content: center;
    }
    h2{
        font-size: 3rem;
        color:#182138;
    }
    h3{
        color:#182138;

    }
    p{
        color:#182138;

    }
}



.uberuns_title{
    display: flex;
    align-items: center;
    justify-content:center ;
    font-size: 2rem;
    color: white;
    background-color: #182138;
    height: 80px;
    width: 100%;
}

.cookie-btn{
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: black;
    outline: none;
    cursor: pointer;
    appearance: none;
  
    /* Custom styles */
    font-weight: bold; /* Makes text bold */
    text-decoration: underline; /* Underlines the text */
    color: black; /* Sets text color to black */
}

.uberuns_text{
    p{
        color:#182138;
        font-size: 1.5rem;
        padding-right: 2.5rem;
        @media screen and (max-width: 1630px) {
            font-size: 1.25rem;
        }
        @media screen and (max-width: 600px) {
            font-size: 1rem;
            padding-right: 1rem;
        }
        @media screen and (max-width: 480px) {
            font-size: 0.7rem;
            padding-right: 0;
        }
    }
    h2{
        font-size: 2.5rem;
        @media screen and (max-width: 1630px) {
            font-size: 2rem;
        }
        @media screen and (max-width: 480px) {
            font-size: 1.5rem;
        }
    }
}


.map{
    z-index: 99;
    @media screen and (max-width: 1300px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    iframe{
        border: 5px solid white;
        width: 750px;
        height: 100%;
        min-height: 500px;
        @media screen and (max-width: 1630px) {
            width:650px;
        }
        @media screen and (max-width: 1375px) {
            width: 80vw;
            margin: auto;
        }
        @media screen and (max-width: 500px) {
            min-height: 300px;
        }
    }
}

.uberuns_right_side{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center ;
        gap: 3rem;
        @media screen and (max-width: 1375px) {
            flex-direction: row;
            width: 100%;
        }
    div{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center ;
       
        img{
            width:200px;
            height:200px;
            object-fit: cover;
            border-radius: 50%;
            @media screen and (max-width: 1630px) {
                width:170px;
            height:170px;
            }
            @media screen and (max-width: 1375px) {
            width:25vw;
            height:25vw;
            }
        }

    }
}

.map-placeholder{
    width: 750px;
    height: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1630px) {
        width:650px;
    }
    @media screen and (max-width: 1375px) {
        width: 80vw;
        margin: auto;
    }
    @media screen and (max-width: 500px) {
        min-height: 300px;
    }
}