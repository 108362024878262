.partner_section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 300px;
  background-color: rgb(240, 240, 240);
  h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1.5rem 0 0.5rem 1rem;
    @media screen and (max-width: 950px) {
      font-size: 1.25rem;
    }
  }
  div {
    padding-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    .partner {
      width: 135px;
      aspect-ratio: 3/2;
      object-fit: contain;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      mix-blend-mode: color-burn;
      cursor: pointer;
      @media screen and (max-width: 950px) {
        width: 13vw;
      }
      &:hover {
        transform: scale(1.02);
      }
    }
    .elektro {
      width: 200px;
      aspect-ratio: 3/2;
      object-fit: contain;
      cursor: pointer;
      @media screen and (max-width: 950px) {
        width: 150px;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.activeNavOption_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1200px;
  gap: 2rem;
  @media screen and (max-width: 600px) {
    gap: 0.8rem;
  }
}
.activeNavOption_container {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40%;
  left: 0;
  z-index: 4;
  @media screen and (max-width: 1400px) {
    bottom: 45%;
  }
  @media screen and (max-width: 1300px) {
    bottom: 55%;
  }
  @media screen and (max-width: 1000px) {
    bottom: 40%;
  }
}
.activeNavOption {
  width: calc(100vw / 8);
  height: 3px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.banner_container {
  max-width: 100%;
  position: relative;
  overflow-x: hidden;

  .navButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 64px;
    aspect-ratio: 1;
    border: 1px solid var(--white-color);
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    z-index: 99;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .prevButton {
    position: absolute;
    left: 40px;
    top: 25%;
  }

  .nextButton {
    position: absolute;
    right: 40px;
    top: 25%;
  }
}

.project_wrapper {
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  transition-duration: 1s;
  transition-property: transform;
  transition-timing-function: ease-in-out;
}

.container_projects {
  backdrop-filter: blur(9.5px);
  box-shadow: 0 3.71429px 46.4286px #0000000d;
  overflow: hidden;
  width: 100vw;
  height: 600px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1400px) {
    height: 500px;
  }
  @media screen and (max-width: 1300px) {
    height: 400px;
  }
  @media screen and (max-width: 1000px) {
    height: 550px;
    align-items: flex-start;
  }
  &:after {
    content: '';
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 65%, rgba(0, 0, 0, 0.324));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.container_inner_project {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 1312px;
  width: calc(100% - 352px);
  height: 600px;
  z-index: 3;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}
.container_left-side {
  padding: 0 2rem 0 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 500px;
  gap: 1rem;
  @media screen and (max-width: 1000px) {
    order: 2;
    width: 100vw;
    gap: 0.5rem;
  }

  h2 {
    font-size: 2.7rem;
    @media screen and (max-width: 1400px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 600px) {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 1.25rem;
    @media screen and (max-width: 1400px) {
      font-size: 1rem;
    }
  }
  button {
    display: block;
    width: 200px;
    align-self: right;
    border: none;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-family: inherit;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    &:hover {
      transform: scale(1.02);
    }
  }
}

.container_right-side {
  height: 100%;
  width: 750px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 1000px) {
    order: 1;
    width: 100vw;
    align-items: flex-start;
    height: 300px;
    margin-bottom: 1rem;
  }
  img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    @media screen and (max-width: 1000px) {
      height: 300px;
    }
  }
}
